import { Flex, Link, Text } from '@aws-amplify/ui-react'
import EquityCalculator from '../pages/EquityCalculator'
import MissingDataCard from './missingDataCard'

export default function PublicApp(): JSX.Element {
  // @ts-ignore
  window.pendo.initialize()

  let page = (
    <Flex justifyContent='center' alignItems='center' height='100vh'>
      <MissingDataCard>
        <Text>Invalid link.</Text>
        <Text>
          Go to back to <Link href='https://app.playbookmna.com'>homepage</Link>
        </Text>
      </MissingDataCard>
    </Flex>
  )
  if (window.location.pathname.includes('/equity-calculator')) {
    const urlParams = new URLSearchParams(window.location.search)
    const companyName = window.location.pathname.split('/equity-calculator/')[1] || urlParams.get('companyName')
    if (companyName) {
      page = <EquityCalculator companyName={companyName} />
    }
  }

  return page
}
